<template>
    <div class="block-list">
        <div class="block-list-wrapper">
            <div class="top-block-container">
                <div class="top-block">
                    <h1 class="title">{{ $t("residencesList.title") }}</h1>
                    <div class="top-right-block-container">
                        <div class="top-right-block">
                            <BasicSearch
                                :searchPlaceholder="
                                    $t('residencesList.searchPlaceholder')
                                "
                                class="search-field"
                                v-model="searchString"
                                @search="setQuery(1, $event)"
                            />
                            <basic-button
                                class="update-all-button"
                                @click="goToV1View('data/majs/list')"
                                color="blue"
                            >
                                <i class="fas fa-sync-alt"></i>
                                <span class="update-text">
                                    {{ $t("global.status.updateAll") }}
                                </span>
                            </basic-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="middle-block-container">
                <div class="middle-block">
                    <div class="count">
                        <h4>
                            {{
                                $t("residencesList.listing", {
                                    count: residencesCount,
                                    first: indexFirst,
                                    last: indexLast,
                                })
                            }}
                        </h4>
                    </div>
                    <div class="display">
                        <div class="mobile">
                            <span>{{ $t("residencesList.display") }}</span>
                            <i
                                class="fas fa-bars mobile"
                                :class="{
                                    activeDisplay: displayMode === 'list',
                                }"
                                @click="display('list')"
                            ></i>
                            <i
                                class="fas fa-th-large"
                                :class="{
                                    activeDisplay: displayMode === 'card',
                                }"
                                @click="display('card')"
                            ></i>
                        </div>
                    </div>
                </div>
            </div>

            <!-- LIST DISPLAY -->
            <div
                class="list-display"
                v-if="displayMode === 'list' && !isLoading"
            >
                <table class="table residences-table">
                    <thead>
                        <tr>
                            <th class="c-picto"></th>
                            <th class="c-ipass mobile"></th>
                            <th class="c-name">
                                {{ $t("residencesList.arrayColumns.name") }}
                            </th>
                            <th class="c-adress mobile">
                                {{ $t("residencesList.arrayColumns.adress") }}
                            </th>
                            <th class="c-status">
                                {{
                                    $t(
                                        "residencesList.arrayColumns.updateStatus"
                                    )
                                }}
                            </th>
                            <th class="c-maj"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-if="checkRight('G_RESIDENCES')"
                            class="create-residence-row"
                            @click="createResidence"
                        >
                            <td class="c-picto">
                                <span>
                                    <div class="create-picto">
                                        <i class="fas fa-city"></i>
                                        <i class="fas fa-plus"></i>
                                    </div>
                                </span>
                            </td>
                            <td></td>
                            <td class="create-title">
                                {{ $t("residencesList.create") }}
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr
                            v-for="item in residences"
                            :key="item.id"
                            :class="{ lastRes: item.id == lastResidenceId }"
                        >
                            <td
                                @click="loadVueResidence(item.id)"
                                class="c-picto"
                            >
                                <span>
                                    <div
                                        v-if="
                                            item.simple == residenceType.COMPLEX
                                        "
                                        class="type-icon-wrapper complex"
                                    >
                                        <img
                                            class="type-icon"
                                            :src="
                                                require('@/assets/images/buildings/complex-residence-mini.png')
                                            "
                                        />
                                    </div>
                                    <div
                                        v-else-if="
                                            item.simple ==
                                            residenceType.SIMPLIFIED
                                        "
                                        class="type-icon-wrapper simplified"
                                    >
                                        <img
                                            class="type-icon"
                                            :src="
                                                require('@/assets/images/buildings/simplified-residence-mini.png')
                                            "
                                        />
                                    </div>
                                    <div
                                        v-else-if="
                                            item.simple == residenceType.BASIC
                                        "
                                        class="type-icon-wrapper basic"
                                    >
                                        <img
                                            class="type-icon"
                                            :src="
                                                require('@/assets/images/buildings/basic-residence-mini.png')
                                            "
                                        />
                                    </div>
                                </span>
                            </td>
                            <td
                                @click="loadVueResidence(item.id)"
                                class="c-ipass mobile"
                            >
                                <span v-if="parseInt(item.robot) === 1">
                                    <img
                                        class="type-icon"
                                        :src="
                                            imageBaseURL +
                                            'Images/ipass_icon.png'
                                        "
                                    />
                                </span>
                            </td>
                            <td
                                @click="loadVueResidence(item.id)"
                                class="c-name"
                            >
                                {{ item.nom }}
                            </td>
                            <td
                                @click="loadVueResidence(item.id)"
                                class="c-adress mobile"
                            >
                                {{ item.adresse }}
                            </td>
                            <td
                                @click="loadVueResidence(item.id)"
                                class="c-status"
                            >
                                <update-status-tag
                                    v-if="item.MAJ"
                                    :status="item.MAJ"
                                    from="residence"
                                ></update-status-tag>
                            </td>
                            <td class="c-maj">
                                <basic-button
                                    v-if="
                                        item.MAJ === 'needupdate' ||
                                        item.MAJ === 'error'
                                    "
                                    class="update-button"
                                    @click="
                                        goToV1View(
                                            'data/majs/list/rid=' + item.id
                                        )
                                    "
                                    color="blue"
                                >
                                    <i class="fas fa-sync-alt"></i>
                                    {{ $t("global.status.update") }}
                                </basic-button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div
                class="list-display"
                v-if="displayMode === 'list' && isLoading"
            >
                <loader class="list-loader"></loader>
            </div>

            <!-- CARD DISPLAY -->
            <div class="card-container">
                <div class="card-wrapper">
                    <div
                        class="card-display"
                        v-if="displayMode === 'card' && !isLoading"
                    >
                        <residence-card
                            :isCreator="true"
                            v-show="checkRight('G_RESIDENCES')"
                        ></residence-card>
                        <residence-card
                            v-for="item in residences"
                            :key="item.id"
                            :id="item.id"
                            :name="item.nom"
                            :type="item.simple"
                            :adress="item.adresse"
                            :updateStatus="item.MAJ"
                            :ipass="item.robot"
                        >
                        </residence-card>
                    </div>
                    <div
                        class="grid-loading"
                        v-if="displayMode === 'card' && isLoading"
                    >
                        <loader class="card-loader"></loader>
                    </div>
                </div>
            </div>

            <!-- PAGINATION -->
            <pagination
                :page="currentPage"
                :pages-count="pagesCount"
                @new-current-page="setPage($event)"
            />
        </div>
    </div>
</template>

<script>
import { getResidences } from "@/services/intratone/residence.js"

import BasicButton from "@/components/basic/BasicButton.vue"
import ResidenceCard from "@/components/entities/ResidenceCard.vue"
import Loader from "@/components/basic/Loader.vue"
import UpdateStatusTag from "@/components/UpdateStatusTag.vue"
import Pagination from "@/components/basic/Pagination"
import BasicSearch from "@/components/basic/BasicSearch.vue"

import v1mixin from "@/mixins/v1.js"

import { updateStatus } from "@/enums"
import { residenceType } from "@/enums"

export default {
    name: "List",
    components: {
        Pagination,
        BasicButton,
        ResidenceCard,
        Loader,
        UpdateStatusTag,
        BasicSearch,
    },
    mixins: [v1mixin],
    data() {
        return {
            // Showed residences
            residences: [],
            // Pagination
            residencesCount: 0,
            pagesCount: 1,
            limitPerPageList: 100,
            limitPerPageCard: 11,
            currentPage: 1,
            searchString: "",
            inputString: "",
            // Enums
            residenceType,
            updateStatus,
            // Results loading
            isLoading: true,
            //last residence
            lastResidenceId: null,
            //url for images
            imageBaseURL: process.env.VUE_APP_IMAGES_URL,
        }
    },
    async created() {
        await this.setQuery(
            this.$store.getters["residences/getPage"],
            this.$store.getters["residences/getSearch"],
            false
        )

        this.searchString = this.$store.getters["residences/getSearch"]
        await this.setPage(1, true)

        if (this.$store.getters["account/getResidenceDisplayChoice"] !== null) {
            this.display(
                this.$store.getters["account/getResidenceDisplayChoice"],
                false
            )
        } else if (
            this.residencesCount <= 9 ||
            document.body.clientWidth <= 768
        ) {
            this.display("card", false)
        } else if (this.residencesCount <= 9) {
            this.display("card", false)
        } else {
            this.display("list", false)
        }

        if (this.$store.getters["account/getResidenceId"] !== null) {
            this.lastResidenceId = this.$store.getters["account/getResidenceId"]
        }
    },
    computed: {
        displayMode() {
            return this.$store.getters["account/getResidenceDisplayChoice"]
        },
        limitPerPage() {
            if (this.displayMode === "card") return this.limitPerPageCard
            else return this.limitPerPageList
        },
        indexFirst() {
            return 1 + (this.currentPage - 1) * this.limitPerPage
        },
        indexLast() {
            return Math.min(
                this.limitPerPage + (this.currentPage - 1) * this.limitPerPage,
                this.residencesCount
            )
        },
    },
    methods: {
        createResidence() {
            this.$router.push({
                name: "CreateResidences",
            })
        },
        display(displayMode, load = true) {
            this.setPage(1, false)
            this.$store.commit("account/setResidenceDisplayChoice", displayMode)
            if (load) {
                this.loadData()
            }
        },
        // Got to residence view
        loadVueResidence(id) {
            this.$router.push({
                name: "Residence",
                params: {
                    id: id,
                },
            })
        },
        async loadResidences(page, search) {
            try {
                // Getting all user residences
                const params = {
                    page: page,
                    limit: this.limitPerPage,
                    q: search,
                }
                let residencesResponse = await getResidences(params)
                if (residencesResponse.data.list.length < 0) {
                    throw new Error()
                }
                this.residences = residencesResponse.data.list
                this.residencesCount = parseInt(
                    residencesResponse.data._count,
                    10
                )
                this.pagesCount =
                    residencesResponse.data._pages > 0
                        ? residencesResponse.data._pages
                        : 1
                this.isLoading = false
            } catch (error) {
                console.log(error)
            }
        },
        async setPage(page, load = true) {
            this.$store.dispatch("residences/setPage", page)
            if (!page) {
                page = 1
            } else if (page < 1) {
                page = 1
            } else if (page > this.pagesCount) {
                page = this.pagesCount
            }
            if (this.$route.name === "Residences") {
                await this.$router.push({
                    query: {
                        ...this.$route.query,
                        page: page,
                    },
                })
            }

            if (load) {
                await this.loadData()
            }
        },
        deleteQuery() {
            if (this.inputString === "") {
                this.setQuery(1, "")
            }
        },
        async setQuery(page, search, load = true) {
            this.currentPage = page
            this.searchString = search
            if (this.$route.name === "Residences") {
                if (!search) {
                    this.$store.dispatch("residences/setSearch", "")
                    await this.$router.push({
                        query: {
                            page: page,
                        },
                    })
                } else {
                    this.$store.dispatch("residences/setSearch", search)
                    await this.$router.push({
                        query: {
                            page: page,
                            search: search,
                        },
                    })
                }

                if (load) {
                    await this.loadData()
                }
            }
        },
        async loadData() {
            this.isLoading = true
            let page = parseInt(this.$route.query.page)
            this.currentPage = page
            let search = this.$route.query.search || ""
            this.searchString = search
            this.inputString = search
            if (page < 1 || page > this.pagesCount || !page) {
                page = 1
            }
            await this.loadResidences(page, search)
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
@import "@/assets/scss/variables/fontSize.scss";

.mobile {
    @media all and (max-width: 768px) {
        display: none;
    }
}

.block-list {
    padding: 40px 44px 0px 44px;
    height: 100%;
    overflow: auto;
    display: flex;
    justify-content: center;
}

.block-list-wrapper {
    width: max-content;
}

@media all and (min-width: 1024px) {
    .top-block-container {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-bottom: 1em;
        .top-block {
            width: 100%;
            max-width: 1448px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 60px;

            @media all and (min-width: 1192px) {
                min-width: 1192px;
            }

            .title {
                font-family: $font_avenir_black;
                font-size: $veryBig;
                width: 25%;
                margin: 0 !important;
            }

            .top-right-block {
                display: flex;
                align-items: center;

                .notif-block {
                    h3 {
                        font-family: $font_avenir_book;
                        font-size: $normal;
                        width: 150px;
                    }
                }

                .update-all-button {
                    width: 240px;
                    margin-left: 20px;

                    i {
                        margin-right: 15px;
                    }
                }

                .search-field {
                    width: 600px;
                    height: 55px;

                    .remove {
                        background-color: $light-grey;
                        border-radius: 0 15px 15px 0;
                        width: 50px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        i {
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    .card-container {
        display: flex;
        width: 100%;
        justify-content: center;
        @media all and (max-width: 1270px) {
            justify-content: center;
        }

        .card-wrapper {
            .grid-loading {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
            }
            .card-display {
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                max-width: 1448px;
                gap: 20px;

                @media all and (max-width: 1920px) {
                    min-width: 1192px;
                }

                @media all and (max-width: 1270px) {
                    grid-template-columns: repeat(4, 1fr);
                    width: 100%;
                    min-width: 100%;
                }

                .residence {
                    margin: 0 30px 30px 0;
                }

                .card-loader {
                    margin: 200px auto 200px auto;
                    place-self: center;
                }
            }
        }
    }
}

@media all and (max-width: 1024px) {
    .block-list {
        padding: 44px 0 0 0;
        .top-block-container {
            display: flex;
            justify-content: center;
            .top-block {
                margin: 0 5px 0 5px;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: max-content;

                .title {
                    font-size: $big;
                }

                .top-right-block {
                    margin: 5px;
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    gap: 20px;

                    .search-field {
                        width: 80%;
                    }

                    .update-all-button {
                        width: 20%;
                    }

                    .search-field {
                        height: 55px;

                        .remove {
                            background-color: $light-grey;
                            border-radius: 0 15px 15px 0;
                            width: 10px;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            i {
                                cursor: pointer;
                            }
                        }
                    }

                    .update-text {
                        display: none;
                    }
                }
            }
        }

        .middle-block-container {
            width: 100%;
            display: flex;
            justify-content: space-between;
            .middle-block {
                width: 100%;
                display: flex;
                justify-content: center;

                .count {
                    @media all and (max-width: 768px) {
                        margin: 0 !important;
                    }
                }

                .mobile {
                    @media all and (max-width: 768px) {
                        display: none;
                    }
                }
            }
        }

        .card-container {
            .card-wrapper {
                .card-display {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    gap: 20px;

                    @media all and (max-width: 768px) {
                        grid-template-columns: repeat(2, 1fr);
                    }

                    @media all and (max-width: 580px) {
                        grid-template-columns: repeat(1, 1fr);
                    }

                    .residence {
                        @media all and (max-width: 768px) {
                            width: 40vw;
                            height: 40vw;
                            place-self: center;

                            .right-details.mobile {
                                display: none;
                            }

                            .left-details.mobile {
                                margin-left: 75%;
                            }
                        }

                        @media all and (min-width: 768px) and (max-width: 1024px) {
                            width: 30vw;
                            height: 30vw;
                        }
                    }
                }

                .grid-loading {
                    display: grid;
                    grid-template-columns: repeat(1, 1fr);
                    height: 100%;
                }
                .card-loader {
                    place-self: center;
                }
            }
        }
    }
}

.glass {
    background-color: $light-grey;
    border-radius: 15px 0 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media all and (max-width: 1024px) {
        width: 60px;
    }

    @media all and (min-width: 1024px) {
        width: 80px;
    }

    i {
        background-color: $orange-neutral;
        padding: 10px;
        border-radius: 5px;
        cursor: pointer;
        font-size: $normal;
        color: $white;
        transition: background-color 200ms;
    }

    i:hover {
        background-color: $orange-light;
    }
}

.form-control {
    background-color: $light-grey;
    border: none;
    font-size: $normal;
}

.form-control:focus {
    background-color: $light-grey;
    border: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.middle-block-container {
    width: 100%;
    display: flex;
    justify-content: center;
    .middle-block {
        max-width: 1448px;
        width: 100%;
        padding: 0 0 15px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .count {
            margin: 0 auto 0 0;

            h4 {
                font-size: $big;
            }
        }

        .display {
            margin: 0 0 auto 0;
            color: $grey-darker;
            font-family: $font_avenir_heavy;
            display: flex;
            align-items: center;

            div {
                display: flex;
                align-items: center;
                gap: 1em;

                span {
                    margin-right: 30px;
                    font-size: $normal;
                }

                i {
                    font-size: $normal;
                    padding: 8px;
                    border-radius: 5px;
                    transition: background-color 200ms, color 200ms;
                }

                i:hover {
                    cursor: pointer;
                    background-color: $grey-neutral;
                    color: $orange-light;
                }

                .activeDisplay {
                    background-color: $grey-dark;
                    color: $white;
                }

                .activeDisplay:hover {
                    background-color: $grey-dark;
                    color: $white;
                }
            }
        }
    }
}

.list-display {
    min-height: 590px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    .residences-table {
        width: 100%;
        margin: 0 auto;

        .c-picto {
            width: 65px;
        }

        .c-ipass {
            width: 40px;
            img {
                max-width: 20px;
            }
        }
        thead {
            border: none;
            color: $grey-darker;
            tr {
                th {
                    border-color: $grey-neutral;
                    font-size: $normal;
                }
            }
        }
        tbody {
            border: none !important;
            .create-residence-row {
                .create-picto {
                    margin-right: 0px;
                    margin-left: auto;
                    color: $orange-neutral;
                    height: 43px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .fa-city {
                        font-size: $big;
                    }

                    .fa-plus {
                        font-size: $normal;
                        padding: 0 0 20px 0;
                    }
                }
                .create-title {
                    font-size: $normal;
                    color: $orange-neutral;
                    font-family: $font_avenir_heavy;
                }
            }
            .create-residence-row:hover {
                background-color: $orange-residence-dark;
            }
            tr {
                background-color: $white;
                transition: background-color 200ms;
                td {
                    border-color: $grey-neutral;
                    vertical-align: middle;
                    padding: 2px;
                }
                td:first-child {
                    text-align: center;
                }
                .c-name {
                    font-family: "Avenir Heavy";
                    font-size: $small;
                }
                .c-picto {
                    text-align: right;

                    .type-icon-wrapper {
                        margin-right: 25px;
                        margin-left: auto;
                        border-radius: 15px;
                        padding: 5px;
                        width: fit-content;

                        .type-icon {
                            width: 32px;
                            pointer-events: none;
                        }
                    }
                    .complex {
                        background-color: $blue-residence-neutral !important;
                    }
                    .simplified {
                        background-color: $orange-residence-neutral !important;
                    }
                    .basic {
                        background-color: $grey-residence-neutral !important;
                        padding-top: 9px;
                        padding-bottom: 9px;
                    }
                }
                .c-adress {
                    font-size: $small;
                }
                .c-display {
                    width: 75px;
                }

                .c-status {
                }

                .c-maj {
                    text-align: center;
                }
                .update-button {
                    width: 150px;
                    height: auto;

                    i {
                        margin-right: 10px;
                    }
                }
            }
            tr:hover {
                cursor: pointer;
                background-color: $grey-light;
            }
            tr.lastRes {
                background-color: $grey-light;
            }
        }
    }
    .list-loader {
        margin: 200px 50% 0 50%;
    }
}

.pagination {
    display: flex;
    margin-top: 30px;
    justify-content: center;

    @media all and (max-width: 1024px) {
        margin-bottom: 60px;
    }
}

.page-number {
    width: 28px;
    height: 28px;
    font-size: $normal;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    transition: background-color 200ms, color 200ms;
}

.page-number:hover {
    background-color: $grey-light;
    cursor: pointer;
    color: $orange-neutral;
}

.active {
    background-color: $blue-light;
    color: $white;
}

.active:hover {
    background-color: $blue-neutral;
    color: $orange-neutral;
}
</style>
